.nav {
    display: flex;
    align-items: center;
    position: right;
    justify-content: flex-end;
    padding: 10px 30px 0px;

    @media (max-width: 500px) { 
        display:flex;
        justify-content: center;
        padding: 10px 10px 0px;
        justify-content: space-around;
     }
}

.nav a {
    font-weight: 300;
    align-items: center;
    text-decoration: none;
    color: black;
    margin: 0px 5px 5px 5px;
    font-family: Roboto Mono;


    @media (max-width: 640px) { 
        font-size:90%;
        margin: 0px 2.5px 2.5px 2.5px;
     }

    @media (max-width:500px) { 
        font-size: 75%;
        margin: 0px 2.5px 2.5px 2.5px;

     }
     @media (max-width:400px) { 
        font-size: 65%;
        margin: 0px 2.5px 2.5px 2.5px;

     }
    
    
}

.nav-items {
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;

    @media (max-width:500px) { 
        width:fit-content;
     }
}

.nav-items:hover {
    color: lightgreen;
    transform: scale(1.1);
    transition: all .1s ease-in-out;
    border-bottom: 1px solid lightgreen;
    
}

