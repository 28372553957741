.contact {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh);

    @media screen and (max-width: 700px)  { 
        height:auto;
    }


}

.contact-title {
    display:flex;
    
}

.contact-title h1{
    font-size: 50px;
    padding: 50px 50px 50px 50px;
    display: flex;
    align-items: center;
    justify-items: center;
    font-family: Roboto;
    font-weight: 10;
    overflow-y:hidden;
    
}

.contact-section {
    display:flex;
    width:100%;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;

    @media screen and (max-width: 700px)  { 
        display:flex;
        flex-direction: column-reverse;
        width:100%;
        overflow:auto;
    }
    

}

.contact-img {
    flex:1;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 700px)  { 
        display:flex;
        width:100%;
        padding-bottom: 50px;
    }  
}

.contact-img img {
    @media screen and (max-width: 700px)  { 
        display:flex;
        width:80%;
        
    } 
}

.contact-form {
    flex:1;
    display:flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;

    @media screen and (max-width: 700px)  { 
        display:flex;
        width:100%;
    }
}

.contact-form form {
    @media screen and (max-width: 700px)  { 
        display:flex;
        width:100%;
    }
}

.contact-form form  * {
    margin: 5px;
}

form {
    width: 70%;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    input {
        width:80%;
        height:30px;
        font-size:14px;
        padding:10px !important;
    }
    input:focus { 
        outline: none !important;
        border:5px  lightgreen;
        border-color: lightgreen !important;
        box-shadow: 0 0 10px lightgreen !important;
    }
    

    textarea {
        width: 80%;
        height:200px;
        font-size:14px;
        padding:10px !important;
    }
    textarea:focus { 
        outline: none !important;
        border-color: lightgreen;
        box-shadow: 0 0 10px lightgreen;
    }

    button {
        width: 150px;
        height: 30px;
        color: white;
        background-color: lightgreen;
        // margin-top: 5px;
    }

    button:active {
        transform: scale(0.9);
        transition: all .1s;
    }

}

.message {
    color: lightgreen;
    padding: 10px; 
}

