@import '../Global.scss';

.header {
    background-color: $mainColor;
    color: black;
    width: 100%;
}

.header-content {
    padding: 15px 30px 0px;
    display: flex;
    justify-content: space-between;
    line-height: 1.5;
    border-bottom: 1px solid #e0e0e0;

    @media (max-width: 500px) { 
        padding: 10px 10px 0px;
     }
}

.header-name {
    font-weight: 200;
    align-items:center;
    display: flex; 
    font-family: Roboto Mono;
    font-weight:100;
    @media screen and (max-width: 500px)  { 
        display:block;
    }
}

.first-name {
    display:flex;
}

.header-name a{
    -webkit-transition: all 2s ease-in-out;
    -moz-transition: all 2s ease-in-out;
    -o-transition: all 2s ease-in-out;
    transition: all 2s ease-in-out;
    cursor:default;
}

.header-name p {
    cursor:default;
}

.header-name a:hover {
    color: lightgreen;
    transition: all .1s ease-in-out;
    -webkit-transition: all .1s ease-in-out;
    -moz-transition: all .1s ease-in-out;
    -o-transition: all .1s ease-in-out;
    cursor:default;
}

.media {
    display: flex;
    align-items: center;

    @media screen and (max-width: 500px)  { 
        display:block;
    }
}



.media-items a {
    text-decoration: none;
    color: black;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    justify-items:center;
    align-items: center;
    display:flex;
    scrollbar-width: none; // for firefox
    &::-webkit-scrollbar{ // for other browsers
        display:none;
    }
    

    
}
.media-items a:hover {
    color: lightgreen;
    transition: all .1s ease-in-out;
    -webkit-transition: all .1s ease-in-out;
    -moz-transition: all .1s ease-in-out;
    -o-transition: all .1s ease-in-out;
}

.icon {
    transition: all 1s ease-in-out;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    padding: 7.5px;
    

}
.icon:hover {
    transform: scale(1.2);
    transition: all .1s ease-in-out;
    -webkit-transition: all .1s ease-in-out;
    -moz-transition: all .1s ease-in-out;
    -o-transition: all .1s ease-in-out
}
