.about {
    background-color: white;
    justify-content: space-between ;
    display: block;
    scrollbar-width: none; // for firefox
    &::-webkit-scrollbar{ // for other browsers
        display:none;
    }
    height: calc(100vh);

    @media (max-height:700px) { 
        height:auto;
        
     }
}

.about-me {
    overflow: hidden;
}


.about-me h1{
    position:relative;
    display:flex;
    align-items: center;
    max-width: fit-content;
    font-family: Roboto Mono;
    font-weight: 100;
}



.imgContainer{
    justify-content:center;
    align-items: center;
    display: flex;
    margin: 0;
    padding: 0;
    height: calc(60vh);
    overflow: hidden;
    @media (max-height:700px) { 
        height:auto;
        
     }
    
}

.imgContainer img {
    height: 100%;
    width: 300px;
    height: 300px;
    border-radius: 50%;

    margin-top: 20px;
    justify-content: center;
    margin:40px;
    box-shadow: 0 0px 50px -10px #000019;

    
}





.about h1::before,
.about h1::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.about h1::before {
    background-color: white;
    animation: typewriter 4s steps(14) forwards;
}

.about h1::after {
    width: 0.125em;
    background: black;
    animation: typewriter 4s steps(14) forwards;
}




.about-me p {
    margin: 10px 0px;
    font-weight: 300;
    font-family: Roboto Mono;
    opacity: 0;
    transform: translateY(3rem);
    animation: fadeInUp 2s ease calc(4s + .5s) forwards;
    
}

.about a {
    color: lightgreen;
    font-family: Roboto Mono;
    font-weight: 300;
}


@keyframes typewriter {
    to {
        left: 100%;
    }
}

@keyframes fadeInUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}