.portfolioList {
    font-size:14px;
    padding: 7px;
    border-radius: 10px;
    cursor:pointer;
    margin-right:25px;
    margin-left:25px;

    @media screen and (max-width: 700px)  { 
        padding:10px;
        overflow-y: hidden;
    }
}

ul {
    justify-items: space-between;
}

.active {
    background-color: lightgreen;
    color:white;
    cursor:pointer;
    
}