.skills {
    display:flex;
    flex-direction:column;
    align-items: center;
    height:100%;

    @media (max-height:700px) { 
        height:auto; 
     }

     @media (max-width:700px) { 
        height:auto; 
     }
}

.skills-title {
    overflow-y: hidden;
}

.skills-title h1{
    font-size: 50px;
    padding: 50px 50px 50px 50px;
    display: flex;
    align-items: center;
    justify-items: center;
    font-family: Roboto;
    font-weight: 10;
    
}


.skills-icon-container {
    height: fit-content;
    display: flex;
    justify-content: center;
    height:100vh;

    @media (max-height:700px) { 
        height:auto;
        
     }

     @media (max-width:700px) { 
        height:auto; 
     }
}

.skills-icon{
    display:grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    align-items: center;
    row-gap: 2rem;
    column-gap: 2rem;
    justify-content: center;
    flex-wrap:wrap;
    justify-items: center;

    
       
}

.skills-icon-img{
    width: 220px;
    display: flex;
    align-items: center;
    justify-content:center;
    flex-direction:column;
    height:100%;
    overflow-y: hidden;
    
    

    // @media screen and (max-width: 1000px)  { 
    //     width: 200px;
    //     height: 100%;
    // }

    @media screen and (max-width: 900px)  { 
        width: 150px;
        height: 100%;
    }

    @media screen and (max-width: 600)  { 
        width: 100px;
        height: 100%;
    }
    


}

.skills-icon-img h1{
    padding: 50px 50px 50px 50px;
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-weight: 400;
    
    flex-direction:column;
    width: max-content;
    height:fit-content;
    font-size:medium;
    padding: 10px;
    overflow-y: hidden;
}

.skills-icon-img img {
    height: max-content;
    width: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    @media screen and (max-width: 1000px)  { 
        width: 100px;
    } 

    @media screen and (max-width: 900px)  { 
        width: 80px;
    } 

    @media screen and (max-width: 800px)  { 
        width: 60px;
    }
    @media screen and (max-width: 500px)  { 
        width: 50px;
    }  

    
}


