*, html {
    scroll-behavior: smooth !important;
}  


.menu {
    height: 100vh;
    padding-right: 10%;
    padding-left: 10%;

    margin: 0 auto !important;
    float: none !important;
    scrollbar-width: none; // for firefox
    &::-webkit-scrollbar{ // for other browsers
        display:none;
    }
}

.sections {
    // width: 100%;
    // height: calc(100vh - 70px);
    // top: 70px;
    scroll-behavior: smooth !important;
    scroll-snap-type: y mandatory;
    scrollbar-width: none; // for firefox
    &::-webkit-scrollbar{ // for other browsers
        display:none;
    }

    height: calc(100vh);
    scroll-snap-align: start;
    
    // * {
        
    //     height: calc(100vh - 70px);
    //     scroll-snap-align: start;
    // }
} 



