.portfolio {
    display:flex;
    align-items: center;
    flex-direction: column;
    height:100vh;

    @media screen and (max-width: 700px)  { 
        height:auto;
    }
}

.title-page {
    font-size: 50px;
    // height: calc(10vh);
    padding:50px 50px 50px 50px;
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-weight: 100;
    overflow-y: hidden;
    @media screen and (max-width: 600px)  { 
        padding:50px 50px 0px 50px;
    }
}

ul {
    margin: 10px;
    padding-bottom: 25px;
    list-style: none;
    display: flex;
    // height: calc(5vh);
    align-items: center;
    overflow-y: hidden;
    justify-content: center;
    padding:10px;
    
    @media screen and (max-width: 700px)  { 
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0px;
    }
}

.portfolio-container {
    // height: calc(60vh);
    display: flex;
    align-items: center;
    overflow-y:hidden;
    height:max-content;
}

.container {
    display:grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    align-items:center;
    justify-content: center;
    flex-wrap:wrap;

    @media screen and (max-width: 900px)  { 
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media screen and (max-width: 600px)  { 
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    
}

.item {
    width: 220px;
    height: 150px;
    border-radius: 20px;
    border: 1px solid lightgrey;
    margin: 10px 20px;
    display: flex;
    align-items: center;
    justify-content:center;
    color: white;
    position: relative;
    transition: all .5s ease;
    cursor:pointer;
}

h3 {
    position: absolute;
    font-size: 20px;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.item:hover {
    background-color:lightgreen;
    img {
    opacity: 0.2;
    z-index:0;
    }
}
